/**
 * @deprecated Prefer hard-coded urls (or more localized constants)
 *
 * Going forward, we prefer hardcoding knowledge base article urls rather than referencing this
 * file. If a certain KB article is used frequently for a certain domain, teams should export
 * a constant related to that domain rather than referencing this file (`trials.kbArticle`
 * not `kbArticles.trials`).
 *
 * Some reasoning:
 * - Many of these articles are only used once and do not make the code more DRY.
 * - Localized rather than global constants enable better ownership and cohesion.
 * - When breaking applications out of the monolith, code can no longer reference this const.
 * - Sharing constants like this across the whole monorepo causes deployent issues.
 */
export default {
  aboutTheGlobalPolicySetting: 'https://jumpcloud.com/support/set-a-global-policy',
  activationLock: 'https://jumpcloud.com/support/create-a-mac-or-ios-activation-lock-policy',
  activityLogFilters: 'https://jumpcloud.com/support/directory-insights-activity-log-filters',
  activityLogViewData: 'https://jumpcloud.com/support/view-the-directory-insights-data-activity-log',
  addDevice: 'https://jumpcloud.com/support/add-a-device',
  addUserAdminPortal: 'https://jumpcloud.com/support/add-users-to-admin-portal',
  adGroupManagement: 'https://jumpcloud.com/support/advanced-configurations-for-ad-sync',
  ADImportAgentKB: 'https://jumpcloud.com/support/get-started-adi',
  adIntegration: 'https://jumpcloud.com/support/get-started-adi',
  adiTroubleshooting: 'https://jumpcloud.com/support/troubleshoot-adi',
  ADJoinedWindowsDevicesSupportKB: 'https://jumpcloud.com/support/jumpcloud-agent-support-for-on-premise-active-directory-and-azure-active-directory-joined-windows-devices',
  adminImplementation: 'https://jumpcloud.com/support/get-started-admin-implementation',
  adSync: 'https://jumpcloud.com/support/configure-real-time-user-provisioning-from-azure-ad',
  ADSyncAgentKB: 'https://jumpcloud.com/support/Configure-the-Active-Directory-Integration#install-ad-sync',
  agentCompatibilityAndRequirements: 'https://jumpcloud.com/support/agent-compatibility-system-requirements-and-impacts',
  agentDeploymentViaCommandLine: 'https://jumpcloud.com/support/install-the-agent-from-command-line',
  alternateEmailForUserOnboarding: 'https://jumpcloud.com/support/alternate-email-for-new-users',
  apiKeyChanges: 'https://jumpcloud.com/support/upcoming-security-changes',
  appAuthorizationConfiguration: 'https://jumpcloud.com/support/understand-mac-keychain-access',
  appEOLorEOS: 'https://jumpcloud.com/support/about-eos-and-eol-for-saml-sso-applications',
  appOverview: 'https://jumpcloud.com/support/get-started-applications',
  assigningPolicyGroup: 'https://jumpcloud.com/support/view-the-directory-insights-data-activity-log',
  attributeDrivenGroupCreation: 'https://jumpcloud.com/support/get-started-user-groups#creating-attribute-driven-groups',
  attributeDrivenGroupSuggestions: 'https://jumpcloud.com/support/get-started-user-groups#adding-attributes-and-viewing-suggestions',
  backgroundTools: 'https://jumpcloud.com/support/get-started-background-access-and-remote-file-manager',
  bambooHrIntegration: 'https://jumpcloud.com/support/integrate-with-bamboohr',
  billingChargesInvoices: 'https://jumpcloud.com/support/faq-billing-charges-and-invoices',
  bindingUsersToDevices: 'https://jumpcloud.com/support/bind-users-to-devices',
  bindingUsersToUserGroup: 'https://jumpcloud.com/support/get-started-user-groups#adding-users-to-a-group',
  bitLockerPolicy: 'https://jumpcloud.com/support/bitlocker-policy',
  bookmarks: 'https://jumpcloud.com/support/connect-apps-using-bookmarks',
  casePortal: 'https://jumpcloud.com/support/use-the-case-portal',
  ceridiandayforcehcm: 'https://jumpcloud.com/support/integrate-with-dayforce',
  changeAndroidEMMDefaultDeviceGroup: 'https://jumpcloud.com/support/add-and-manage-android-devices',
  changeAppleMDMDefaultDeviceGroup: 'https://jumpcloud.com/support/change-a-default-device-group-for-apple-devices',
  commandsGallery: 'https://github.com/TheJumpCloud/support/tree/master/PowerShell/JumpCloud%20Commands%20Gallery',
  commandTriggers: 'https://jumpcloud.com/support/use-command-triggers',
  conditionalPoliciesGettingStarted: 'https://jumpcloud.com/support/get-started-conditional-access-policies',
  conditionalPolicyDeviceCertificates: 'https://jumpcloud.com/support/manage-conditional-access-policy-certificates',
  configurationAlerts: 'https://jumpcloud.com/support/get-started-jumpcloud-alerts',
  connectingGroupToResources: 'https://jumpcloud.com/support/get-started-user-groups#ConnectingtoResources',
  creatingDeviceGroup: 'https://jumpcloud.com/support/create-a-device-group',
  creatingIOSPolicies: 'https://jumpcloud.com/support/configure-settings-for-ios-policies',
  creatingPolicies: 'https://jumpcloud.com/support/create-a-policy',
  creatingWindowsVM: 'https://jumpcloud.com/support/use-a-windows-virtual-machine-to-test-jumpcloud',
  customImportUsersFromApiIntegration: 'https://jumpcloud.com/support/import-users-with-a-custom-api-integration',
  customScimIdentityManagement: 'https://jumpcloud.com/support/provision-and-manage-users-and-groups-in-apps-using-custom-scim-identity-management-integration',
  deployMdmCustomConfigurationProfile: 'https://jumpcloud.com/support/create-mac-or-ios-mdm-custom-configuration-profile-policy',
  devicePasswordSync: 'https://jumpcloud.com/support/device-password-sync',
  directoryInsights: 'https://jumpcloud.com/support/directory-insights',
  distributionGroupManagement: 'https://jumpcloud.com/support/manage-google-workspace-and-m365-azure-ad-groups',
  duoMfaSetup: 'https://jumpcloud.com/support/configure-duo-security-mfa',
  dynamicGroupsSystemGroups: 'https://jumpcloud.com/support/configure-dynamic-device-groups',
  dynamicGroupsUserGroups: 'https://jumpcloud.com/support/dynamic-group-configuration',
  emailCustomization: 'https://jumpcloud.com/support/settings-in-admin-portal#customize-email',
  emailTokens: 'https://jumpcloud.com/support/settings-in-admin-portal#available-tokens',
  emmConfigurePolicy: 'https://jumpcloud.com/support/configure-settings-for-android-policies',
  emmManageDevice: 'https://jumpcloud.com/support/create-an-android-runtime-permissions-policy',
  emmSetUp: 'https://jumpcloud.com/support/set-up-android-emm',
  emmSoftwareManagement: 'https://jumpcloud.com/support/software-management-android',
  enablingSambaSupportWithLdap: 'https://jumpcloud.com/support/configure-samba-support-to-use-cloud-ldap',
  enablingUsersInstallAgent: 'https://jumpcloud.com/support/enable-users-to-install-the-agent',
  enrollingDevicesWithUserApproval: 'https://jumpcloud.com/support/enroll-macos-devices-with-user-approval',
  enrolllingAndroidDevicesInEmm: 'https://jumpcloud.com/support/users-enroll-your-personal-android-device',
  enrolllingIosDevicesInMdm: 'https://jumpcloud.com/support/add-personal-apple-devices-to-mdm-with-user-enrollment',
  externallyManagedPasswords: 'https://jumpcloud.com/support/externally-managed-passwords ',
  faqPricingQuestions: 'https://jumpcloud.com/support/faq-purchase-in-the-admin-portal',
  fileVault2SystemPolicy: 'https://jumpcloud.com/support/create-a-mac-filevault-2-policy',
  findDeviceID: 'https://jumpcloud.com/support/contact-jumpcloud-support',
  getStartedApplications: 'https://jumpcloud.com/support/get-started-applications',
  gettingStarted: 'https://jumpcloud.com/support/get-started',
  gettingStartedActiveDirectory: 'https://jumpcloud.com/support/get-started-adi',
  gettingStartedApplications: 'https://jumpcloud.com/support/get-started-applications-saml-sso',
  gettingStartedCommands: 'https://jumpcloud.com/support/get-started-commands',
  gettingStartedDeviceGroups: 'https://jumpcloud.com/support/get-started-device-groups',
  gettingStartedDevices: 'https://jumpcloud.com/support/get-started-devices',
  gettingStartedGroups: 'https://jumpcloud.com/support/get-started-user-groups',
  gettingStartedJCGo: 'https://jumpcloud.com/support/get-started-jumpcloud-go',
  gettingStartedMdm: 'https://jumpcloud.com/support/get-started-mdm',
  gettingStartedPolicies: 'https://jumpcloud.com/support/get-started-policies',
  gettingStartedRadius: 'https://jumpcloud.com/support/get-started-radius',
  gettingStartedSaml2SSO: 'https://support.jumpcloud.com/support/s/topic/0TO1M000000EUwyWAG/applications-saml-sso', // must change in code first
  gettingStartedSecurityCommands: 'https://jumpcloud.com/support/use-windows-and-linux-security-commands',
  gettingStartedSignInWithJumpCloud: 'https://jumpcloud.com/support/get-started-sign-in-with-jumpcloud',
  gettingStartedSoftwareApps: 'https://jumpcloud.com/support/software-management-windows',
  gettingStartedSystems: 'https://jumpcloud.com/support/get-started-devices',
  gettingStartedUsers: 'https://jumpcloud.com/support/get-started-users',
  gettingStartedWorkday: 'https://jumpcloud.com/support/integrate-with-workday',
  GoogleAppsKB: 'https://jumpcloud.com/support/google-workspace-integration-overview',
  grantRemoteAssistRecordingMac: 'https://jumpcloud.com/support/grant-screen-recording-and-accessibility-permissions-for-remote-assist-agent-on-macos-devices',
  gratingPermissionMonterey: 'https://jumpcloud.com/support/grant-full-disk-access-permissions-to-the-jumpcloud-agent-for-macos',
  groupInheritedUserAttributes: 'https://jumpcloud.com/support/group-inherited-user-attributes',
  gSuiteImport: 'https://jumpcloud.com/support/google-workspace-sync',
  gSuiteImportManageGWGroups: 'https://jumpcloud.com/support/google-workspace-integration-overview#managing-google-workspace-groups',
  gSuiteIntegrationVsSamlConnector: 'https://jumpcloud.com/support/understand-the-difference-between-the-google-workspace-integration-and-saml-connector',
  identityManagementConnectors: 'https://jumpcloud.com/support/get-started-identity-management-connectors',
  identityProviderConfigurationAzure: 'https://jumpcloud.com/support/configure-azure-as-identity-provider',
  identityProviderConfigurationGoogle: 'https://jumpcloud.com/support/configure-google-workspace-identity-provider',
  identityProviderConfigurationOkta: 'https://jumpcloud.com/support/configure-okta-as-an-identity-provider',
  importUsersFromCsv: 'https://jumpcloud.com/support/add-users-to-admin-portal#importing-users-via-csv-file',
  importUsersFromCustomRestApi: 'https://jumpcloud.com/support/import-users-with-a-custom-api-integration',
  installAndroid: 'https://jumpcloud.com/support/add-and-manage-android-devices',
  installCrowdStrikeFalconAgent: 'https://jumpcloud.com/support/install-the-crowdstrike-falcon-agent',
  installLinuxAgent: 'https://jumpcloud.com/support/install-the-linux-agent',
  installMacAgent: 'https://jumpcloud.com/support/install-the-mac-agent',
  installWindowsAgent: 'https://jumpcloud.com/support/install-the-windows-agent#installing-the-windows-agent-from-terminal',
  InstallWindowsAgentSteps: 'https://jumpcloud.com/support/jumpcloud-agent-windows-installation-walkthrough',
  integratingWithDeel: 'https://jumpcloud.com/support/integrate-with-deel',
  integratingWithFactorialHR: 'https://jumpcloud.com/support/integrate-with-factorial',
  integratingWithGusto: 'https://jumpcloud.com/support/Integrate-with-Gusto',
  integratingWithHiBob: 'https://jumpcloud.com/support/integrate-with-bob',
  ipList: 'https://jumpcloud.com/support/get-started-ip-lists',
  jcGo: 'https://jumpcloud.com/support/get-started-jumpcloud-go',
  jcLdapToOkta: 'https://jumpcloud.com/support/configure-okta-to-use-cloud-ldap',
  jcProtectForAdmins: 'https://jumpcloud.com/support/jumpcloud-protect-for-admins',
  jcProtectOrgSetup: 'https://jumpcloud.com/support/jumpcloud-protect-for-admins',
  ldapCloudService: 'https://jumpcloud.com/support/use-cloud-ldap',
  LdapKB: 'https://jumpcloud.com/support/use-cloud-ldap',
  ldapMFAConfiguration: 'https://jumpcloud.com/support/configure-mfa-for-ldap',
  legalUpdateTerms: 'https://jumpcloud.com/legal',
  localUserAccount: 'https://jumpcloud.com/support/take-over-an-existing-user-account-with-jumpcloud',
  macVenturaInstallation: 'https://jumpcloud.com/support/create-a-mac-policy-to-delay-or-block-ventura',
  maidScript: 'https://jumpcloud.com/support/run-the-maid-import-script',
  managedSoftwareCustomPackage: 'https://jumpcloud.com/support/software-management-windows#createsoftware',
  managingApplicationIDPCertificate: 'https://jumpcloud.com/support/manage-application-idp-certificate-and-key-pairs',
  managingUserStates: 'https://jumpcloud.com/support/manage-user-states',
  mdmAdminConfigurationHref: 'https://jumpcloud.com/support/add-company-owned-apple-devices-to-mdm-with-device-enrollment#adding-ios-devices-to-mdm-',
  mdmCommands: 'https://jumpcloud.com/support/mdm-commands',
  mdmCustomPolicy: 'https://jumpcloud.com/support/create-a-mac-mdm-enrollment-policy',
  mdmCustomPolicyMigration: 'https://jumpcloud.com/support/create-a-mac-mdm-enrollment-policy',
  mdmDepConfigure: 'https://jumpcloud.com/support/configure-ade#ToSyncDevice',
  mdmDepIntegration: 'https://jumpcloud.com/support/choose-an-mdm-enrollment-method',
  mdmDepSync: 'https://jumpcloud.com/support/configure-ade',
  mdmEnrollmentPolicy: 'https://jumpcloud.com/support/create-a-mac-mdm-enrollment-policy',
  mdmIOSUserEnrollment: 'https://jumpcloud.com/support/users-enroll-your-personal-ios-device',
  mdmManageDevices: 'https://jumpcloud.com/support/get-started-mdm',
  mdmProfileApproval: 'https://jumpcloud.com/support/create-a-mac-mdm-enrollment-policy#approving-an-mdm-enrollment-profile',
  mdmVpp: 'https://jumpcloud.com/support/set-up-apples-vpp',
  mfaFactorGuide: 'https://jumpcloud.com/support/mfa-for-admins',
  mfaForAdmins: 'https://jumpcloud.com/support/mfa-for-admins',
  mfaSetupForOrg: 'https://jumpcloud.com/support/configure-mfa-for-org',
  migrating: 'https://jumpcloud.com/support/migrate-users-from-active-directory',
  O365KB: 'https://jumpcloud.com/support/m365-directory-integration-overview',
  office365IntegrationVsSamlConnector: 'https://jumpcloud.com/support/m365-directory-integration-and-saml-connector',
  officeImport: 'https://jumpcloud.com/support/m365-directory-integration-overview',
  oktaIntegration: 'https://jumpcloud.com/support/configure-okta-real-time-user-password-import',
  onboardingUserStateSurvey: 'https://www.surveymonkey.com/r/9WFBPMC',
  osPatchManagement: 'https://jumpcloud.com/support/get-started-patch-management',
  passwordManagement: 'https://jumpcloud.com/support/get-started-jumpcloud-password-manager',
  passwordSettings: 'https://jumpcloud.com/support/manage-password-and-security-settings',
  patchManagement: 'https://jumpcloud.com/support/create-a-universal-browser-patch-policy',
  patchManagementAutoMacOSUpdPlcy: 'https://jumpcloud.com/support/users-updating-your-macos',
  patchManagementGettingStarted: 'https://jumpcloud.com/support/get-started-patch-management',
  patchManagementLearnMore: 'https://jumpcloud.com/support/choose-an-os-patch-management-policy',
  pciCompliant: 'https://support.jumpcloud.com/support/s/article/pci-compliant-password-complexity-setting-incompatible-with-windows1', // no redirect
  personio: 'https://jumpcloud.com/support/integrate-with-personio',
  powershellModule: 'https://jumpcloud.com/support/jumpcloud-powershell-module',
  preparingmacosBigSur: 'https://jumpcloud.com/support/configure-settings-for-mac-policies',
  professionalServices: 'https://jumpcloud.com/professional-services',
  push: 'https://jumpcloud.com/support/jumpcloud-protect-for-admins',
  radiusBYOC: 'https://jumpcloud.com/support/certificate-based-authentication-to-radius-for-admins',
  radiusConfiguration: 'https://jumpcloud.com/support/radius-configuration-and-authentication',
  radiusDeviceConfiguration: 'https://jumpcloud.com/support/configure-a-wap-vpn-or-router-for-radius',
  radiusMfaConfiguration: 'https://jumpcloud.com/support/authenticate-to-radius-with-mfa',
  radiusProtocolSupport: 'https://jumpcloud.com/support/radius-protocol-support',
  radiusQuickStartAzure: 'https://jumpcloud.com/support/authenticate-to-radius-with-azure-ad',
  radiusReplyAttributes: 'https://jumpcloud.com/support/configure-radius-reply-attributes-in-the-api',
  remoteAgentInstall: 'https://jumpcloud.com/support/enable-users-to-install-the-agent',
  remoteAssist: 'https://jumpcloud.com/support/get-started-remote-assist',
  remoteAssistFaq: 'https://jumpcloud.com/support/faq-remote-assist',
  rules: 'https://jumpcloud.com/support/configure-rules-for-jumpcloud-alerts',
  roles: 'https://jumpcloud.com/support/admin-portal-roles',
  samlConnectorWithJitAttributes: 'https://jumpcloud.com/support/saml-supported-jit-provisioning',
  samlConnectorWithoutJitAttributes: 'https://jumpcloud.com/support/configure-user-attributes-for-saml-connectors',
  scim15five: 'https://jumpcloud.com/support/integrate-with-15five',
  scim1password: 'https://jumpcloud.com/support/integrate-with-1password',
  scim4me: 'https://jumpcloud.com/support/integrate-with-4me',
  scim8x8: 'https://jumpcloud.com/support/integrate-with-8x8',
  scimAdpIdm: 'https://jumpcloud.com/support/',
  scimAirtable: 'https://support.jumpcloud.com/s/article/Identity-Management-with-Airtable', // no redirect
  scimAlexisHR: 'https://support.jumpcloud.com/s/article/Identity-Management-with-AlexisHR', // no redirect
  scimAltium: 'https://jumpcloud.com/support/integrate-with-altium',
  scimAmplitude: 'https://jumpcloud.com/support/integrate-with-Amplitude',
  scimAppleBusinessManager: 'https://jumpcloud.com/support/integrate-with-Apple-Business-Manager',
  scimAsana: 'https://jumpcloud.com/support/integrate-with-asana',
  scimAssetSonar: 'https://jumpcloud.com/support/integrate-with-AssetSonar',
  scimAtlassianCloud: 'https://jumpcloud.com/support/integrate-with-Atlassian-cloud',
  scimAutodesk: 'https://support.jumpcloud.com/s/article/Identity-Management-with-Autodesk', // no redirect
  scimAws: 'https://jumpcloud.com/support/integrate-with-aws-iam-identity-center',
  scimAwsSSO: 'https://jumpcloud.com/support/integrate-with-aws-iam-identity-center#configuring-the-sso-integration',
  scimBitwarden: 'https://jumpcloud.com/support/integrate-with-bitwarden',
  scimBlink: 'https://jumpcloud.com/support/integrate-with-blink',
  scimBlogIn: 'https://jumpcloud.com/support/integrate-with-blogin',
  scimBluejeans: 'https://support.jumpcloud.com/s/article/Identity-Management-with-BlueJeans', // no redirect
  scimBonusly: 'https://support.jumpcloud.com/s/article/Identity-Management-with-Bonusly', // no redirect
  scimBrowserstack: 'https://jumpcloud.com/support/integrate-with-browserstack',
  scimCalendly: 'https://jumpcloud.com/support/integrate-with-calendly',
  scimCinode: 'https://jumpcloud.com/support/integrate-with-cinode',
  scimCiscowebex: 'https://jumpcloud.com/support/integrate-with-cisco-webex',
  scimClickup: 'https://jumpcloud.com/support/integrate-with-clickup',
  scimCloudflareaccess: 'https://support.jumpcloud.com/s/article/Identity-Management-with-CloudFlareAccess', // no redirect
  scimCloudUmbrellabyCisco: 'https://jumpcloud.com/support/integrate-with-cloud-umbrella-by-cisco',
  scimCoda: 'https://jumpcloud.com/support/integrate-with-coda',
  scimContentful: 'https://jumpcloud.com/support/integrate-with-contentful',
  scimCoralogix: 'https://jumpcloud.com/support/integrate-with-coralogix',
  scimDashlane: 'https://jumpcloud.com/support/integrate-with-dashlane',
  scimDatabricks: 'https://jumpcloud.com/support/integrate-with-databricks',
  scimDialpad: 'https://jumpcloud.com/support/integrate-with-dialpad',
  scimDocker: 'https://jumpcloud.com/support/integrate-with-docker',
  scimDruva: 'https://jumpcloud.com/support/integrate-with-druva',
  scimEgnyte: 'https://jumpcloud.com/support/integrate-with-egnyte',
  scimEnvoy: 'https://support.jumpcloud.com/support/s/article/Identity-Management-with-Envoy', // no redirect
  scimEvernote: 'https://jumpcloud.com/support/integrate-with-evernote',
  scimExium: 'https://jumpcloud.com/support/integrate-with-exium',
  scimFigma: 'https://jumpcloud.com/support/integrate-with-figma',
  scimForcepointPrivateAccess: 'https://jumpcloud.com/support/integrate-with-forcepoint-private-access',
  scimFront: 'https://support.jumpcloud.com/s/article/Identity-Management-with-Front', // no redirect
  scimGetAbstract: 'https://support.jumpcloud.com/s/article/Identity-Management-with-getAbstract', // no redirect
  scimGitHub: 'https://jumpcloud.com/support/integrate-with-github-enterprise-cloud',
  scimGitlab: 'hhttps://jumpcloud.com/support/integrate-with-gitlab',
  scimGitlabInstance: 'https://support.jumpcloud.com/s/article/Identity-Management-with--gitlab-instance', // no redirect
  scimGolinks: 'https://jumpcloud.com/support/integrate-with-golinks',
  scimGong: 'https://jumpcloud.com/support/integrate-with-gong',
  scimGoodAccess: 'https://jumpcloud.com/support/integrate-with-goodaccess',
  scimGrammarly: 'https://jumpcloud.com/support/integrate-with-grammarly',
  scimGreenhouse: 'https://jumpcloud.com/support/integrate-with-greenhouse',
  scimGuru: 'https://jumpcloud.com/support/integrate-with-guru',
  scimHeap: 'https://jumpcloud.com/support/integrate-with-heap',
  scimHoxhunt: 'https://jumpcloud.com/support/integrate-with-hoxhunt',
  scimInteract: 'https://jumpcloud.com/support/integrate-with-interact-software',
  scimIronclad: 'https://jumpcloud.com/support/integrate-with-ironclad',
  scimIsland: 'https://jumpcloud.com/support/integrate-with-island',
  scimJfrog: 'https://support.jumpcloud.com/s/article/Identity-Management-with-Jfrog', // no redirect
  scimKandji: 'https://jumpcloud.com/support/integrate-with-kandji',
  scimKeeper: 'https://jumpcloud.com/support/integrate-with-keeper',
  scimKisi: 'https://jumpcloud.com/support/integrate-with-kisi',
  scimKlaviyo: 'https://jumpcloud.com/support/integrate-with-klaviyo',
  scimKnowbe4: 'https://jumpcloud.com/support/integrate-with-knowbe4',
  scimKrisp: 'https://jumpcloud.com/support/integrate-with-krisp',
  scimLattice: 'https://jumpcloud.com/support/integrate-with-lattice',
  scimLeapsome: 'https://jumpcloud.com/support/integrate-with-leapsome',
  scimLooop: 'https://jumpcloud.com/support/integrate-with-looop',
  scimLucidchart: 'https://jumpcloud.com/support/integrate-with-lucidchart',
  scimMiro: 'https://jumpcloud.com/support/integrate-with-miro',
  scimMixpanel: 'https://jumpcloud.com/support/integrate-with-mixpanel',
  scimMonday: 'https://jumpcloud.com/support/integrate-with-monday-com',
  scimMural: 'https://support.jumpcloud.com/s/article/Identity-Management-with-Mural', // no redirect
  scimNamely: 'https://jumpcloud.com/support/integrate-with-namely',
  scimNetskope: 'https://jumpcloud.com/support/integrate-with-netskope',
  scimNewrelic: 'https://jumpcloud.com/support/integrate-with-new-relic',
  scimNinjio: 'https://jumpcloud.com/support/integrate-with-ninjio',
  scimNotion: 'https://jumpcloud.com/support/integrate-with-notion',
  scimOfficespace: 'https://jumpcloud.com/support/integrate-with-officespace',
  scimOnetrust: 'https://jumpcloud.com/support/integrate-with-onetrust',
  scimOraclecloud: 'https://jumpcloud.com/support/integrate-with-oracle-cloud',
  scimPaylocity: 'https://jumpcloud.com/support/integrate-with-paylocity',
  scimPaloaltonetworks: 'https://jumpcloud.com/support/integrate-with-palo-alto-networks',
  scimPerimeter81: 'https://support.jumpcloud.com/s/article/Identity-Management-with-Perimeter81', // no redirect
  scimPipefy: 'https://jumpcloud.com/support/integrate-with-pipefy',
  scimPrinterlogicsaas: 'https://jumpcloud.com/support/integrate-with-printerlogic-saas',
  scimProfit: 'https://jumpcloud.com/support/integrate-with-profit-co',
  scimPromapp: 'https://jumpcloud.com/support/integrate-with-promapp',
  scimProxyclick: 'https://jumpcloud.com/support/integrate-with-proxyclick',
  scimPulumi: 'https://jumpcloud.com/support/integrate-with-pulumi',
  scimQuip: 'https://support.jumpcloud.com/s/article/Identity-Management-with-Quip', // no redirect
  scimRhombus: 'https://jumpcloud.com/support/integrate-with-rhombus-systems',
  scimRingcentral: 'https://jumpcloud.com/support/integrate-with-ringcentral',
  scimRobin: 'https://jumpcloud.com/support/integrate-with-robin',
  scimSalesforce: 'https://jumpcloud.com/support/integrate-with-salesforce',
  scimSalesforcesandbox: 'https://jumpcloud.com/support/integrate-with-salesforce',
  scimSalesHood: 'https://jumpcloud.com/support/integrate-with-saleshood',
  scimScreenstepslive: 'https://support.jumpcloud.com/s/article/Identity-Management-with-ScreenSteps', // no redirect
  scimSegment: 'https://jumpcloud.com/support/integrate-with-segment',
  scimSentry: 'https://support.jumpcloud.com/support/s/article/Identity-Management-with-Sentry', // no redirect
  scimShowpad: 'https://jumpcloud.com/support/integrate-with-showpad',
  scimSigma: 'https://jumpcloud.com/support/integrate-with-sigma',
  scimSlack: 'https://jumpcloud.com/support/integrate-with-slack',
  scimSmartsheet: 'https://jumpcloud.com/support/integrate-with-smartsheet',
  scimSnipeit: 'https://support.jumpcloud.com/s/article/Identity-Management-with-SnipeIT', // no redirect
  scimSnowflake: 'https://jumpcloud.com/support/integrate-with-snowflake',
  scimSpaceiq: 'https://jumpcloud.com/support/integrate-with-spaceiq',
  scimSparkpost: 'https://jumpcloud.com/support/integrate-with-sparkpost',
  scimSplashtop: 'https://jumpcloud.com/support/integrate-with-splashtop',
  scimStackoverflowforteams: 'https://jumpcloud.com/support/integrate-with-stack-overflow-for-teams',
  scimStrongdm: 'https://jumpcloud.com/support/integrate-with-strongdm',
  scimTableau: 'https://jumpcloud.com/support/integrate-with-tableau',
  scimTalentlms: 'https://support.jumpcloud.com/s/article/Identity-Management-with-TalentLMS', // no redirect
  scimTeamretro: 'https://jumpcloud.com/support/integrate-with-teamretro',
  scimTeamviewer: 'https://jumpcloud.com/support/integrate-with-teamviewer',
  scimTemplafy: 'https://support.jumpcloud.com/s/article/Identity-Management-with-Templafly', // no redirect
  scimTerranova: 'https://jumpcloud.com/support/integrate-with-terranova-security',
  scimTerratrue: 'https://jumpcloud.com/support/integrate-with-terratrue',
  scimThousandeyes: 'https://support.jumpcloud.com/support/s/article/Identity-Management-with-ThousandEyes', // no redirect
  scimThrivelearning: 'https://jumpcloud.com/support/integrate-with-thrive-learning',
  scimTrello: 'https://support.jumpcloud.com/s/article/Identity-Management-with-Trello', // no redirect
  scimTwingate: 'https://jumpcloud.com/support/integrate-with-twingate',
  scimUdemybusiness: 'https://jumpcloud.com/support/integrate-with-udemy-business',
  scimVerkada: 'https://support.jumpcloud.com/s/article/Identity-Management-with-Verkada', // no redirect
  scimVimeo: 'https://support.jumpcloud.com/s/article/Identity-Management-with-Vimeo', // no redirect
  scimWhimsical: 'https://jumpcloud.com/support/integrate-with-whimsical',
  scimWorkgrid: 'https://jumpcloud.com/support/integrate-with-workgrid',
  scimWorkivawdesk: 'https://jumpcloud.com/support/integrate-with-workivawdesk',
  scimWorkplacebyfacebook: 'https://jumpcloud.com/support/integrate-with-workplace-by-facebook',
  scimWorkramp: 'https://jumpcloud.com/support/integrate-with-workramp',
  scimZeplin: 'https://jumpcloud.com/support/integrate-with-zeplin',
  scimZoom: 'https://jumpcloud.com/support/integrate-with-zoom',
  scimZoomoauth: 'https://jumpcloud.com/support/integrate-with-zoom',
  scimZscaler: 'https://jumpcloud.com/support/integrate-with-zscaler',
  search: 'https://jumpcloud.com/support',
  securityInfoUrl: 'https://jumpcloud.com/support/get-started-background-access-and-remote-file-manager#security-best-practices:-commonly-masked-commands',
  securitySettingsPassword: 'https://jumpcloud.com/support/manage-password-and-security-settings',
  selfServiceAccountProvisioning: 'https://jumpcloud.com/support/get-started-self-service-account-provisioning',
  settingAdministratorSudoPermission: 'https://jumpcloud.com/support/set-admin-sudo-permissions',
  settingAppleVpp: 'https://jumpcloud.com/support/set-up-apples-vpp',
  settingUpMdm: 'https://jumpcloud.com/support/set-up-apple-mdm',
  setupDuoSecurityMfa: 'https://jumpcloud.com/support/configure-duo-security-mfa',
  softwareApplicationDelete: 'https://jumpcloud.com/support/software-management-windows#delete',
  ssoApplicationsUserAuth: 'https://jumpcloud.com/support/authorize-users-to-an-sso-application',
  ssoToApplications: 'https://jumpcloud.com/support/get-started-applications',
  ssoWithOIDC: 'https://jumpcloud.com/support/sso-with-oidc',
  ssoWithSamlConnector: 'https://jumpcloud.com/support/sso-using-custom-saml-application-connectors',
  status: 'https://status.jumpcloud.com/',
  supervisedDevicePolicies: 'https://jumpcloud.com/support/understand-supervised-devices-and-policies',
  support: 'https://jumpcloud.com/support',
  supportContact: 'https://jumpcloud.com/support/contact-jumpcloud-support',
  supportingVpp: 'https://jumpcloud.com/support/support-vpp-apps',
  systemDirectoryInsights: 'https://jumpcloud.com/support/settings-in-admin-portal#directory-insights',
  systemInsights: 'https://jumpcloud.com/support/settings-in-admin-portal#system-insights',
  systemInsightsEnable: 'https://jumpcloud.com/support/system-insights#enabling-system-insights',
  systemInsightsView: 'https://jumpcloud.com/support/system-insights',
  totpSetup: 'https://jumpcloud.com/support/configure-totp-mfa-for-user-accounts',
  unbindingUsers: 'https://jumpcloud.com/support/unbind-users-from-a-resource',
  understandAgent: 'https://jumpcloud.com/support/understand-the-agent',
  userGroupsRadiusReplyAttributes: 'https://jumpcloud.com/support/configure-radius-reply-attributes-for-user-groups',
  userNamingConvention: 'https://jumpcloud.com/support/naming-conventions-for-users',
  usingMfa: 'https://jumpcloud.com/support/mfa-for-users',
  usingSecureTokens: 'https://jumpcloud.com/support/troubleshooting-service-account',
  vppSupport: 'https://jumpcloud.com/support/support-vpp-apps',
  webAuthn: 'https://jumpcloud.com/support/set-up-webauthn',
  windowMdmKB: 'https://jumpcloud.com/support/get-started-windows-mdm',
  workdayIntegration: 'https://jumpcloud.com/support/integrate-with-workday',
  workFromHomeGuide: 'https://jumpcloud.com/resources/working-from-home-jumpcloud-admin-guide',
  zeroTrust: 'https://jumpcloud.com/support/get-started-conditional-access-policies',
};
