import { setupFeatureFlags } from '@jumpcloud/ui-feature-flags';

const flagConfigs = [
  {
    localName: 'abmConnector',
    sourceId: '2024-06-03-show-ABM-app-in-catalog',
  },
  {
    localName: 'adiConfigStepper',
    sourceId: '2023-12-04-adi-config-stepper-and-new-details-view',
  },
  {
    localName: 'adminMfaEnforcement',
    sourceId: '2024-04-09-mfa-enforcement',
  },
  {
    localName: 'adminMfaEnforcementCustomerComms',
    sourceId: '2024-04-17-mfa-enforcement-customer-comms',
  },
  {
    localName: 'adpIdmConnector',
    sourceId: '2023-10-06-adp-internal-scim-import',
  },
  {
    localName: 'allowOsCondition',
    sourceId: '2024-03-27-os-cap-condition',
  },
  {
    localName: 'androidEmmFullyManaged',
    sourceId: '2023-05-12-android-emm-fullymanaged-dedicated',
  },
  {
    localName: 'androidEmmZeroTouch',
    sourceId: '2023-06-21-android-emm-zero-touch',
  },
  {
    localName: 'apiKeyAccessState',
    sourceId: '2024-03-27-api-key-visibility',
  },
  {
    localName: 'apiKeyExpiration',
    sourceId: '2024-03-27-api-key-expiration',
  },
  {
    localName: 'applicationCatalog',
    sourceId: '2024-05-16-application-catalog',
  },
  {
    localName: 'browserPatchReport',
    sourceId: '2022-09-14-browser-patch-report',
  },
  {
    localName: 'byodVpp',
    sourceId: '2023-03-02-BYOD-VPP',
  },
  {
    localName: 'clearIosRestrictionsPassword',
    sourceId: '2024-04-11-clear-ios-restrictions-password',
  },
  {
    localName: 'deviceAsAGateway',
    sourceId: '2023-03-01-device-as-gateway',
  },
  {
    localName: 'addDeviceComponentLibraryUpdates',
    sourceId: '2024-03-29-add-device-page-update',
  },
  {
    localName: 'deviceAsideHighlightsTab',
    sourceId: '2021-07-14-device-aside-highlights-tab',
  },
  {
    localName: 'deviceClearPasscode',
    sourceId: '2024-04-04-clear-device-passcode',
  },
  {
    localName: 'deviceHealthMonitoring',
    sourceId: '2024-03-01-monitoring',
  },
  {
    localName: 'deviceListUpdates',
    sourceId: '2024-06-10-Device-List-Update',
  },
  {
    localName: 'deviceLostMode',
    sourceId: '2024-04-02-enable-disable-lost-mode',
  },
  {
    localName: 'deviceOverviewEndOfService',
    sourceId: '2022-12-12-device-overview-end-of-service',
  },
  {
    localName: 'deviceTrust',
    sourceId: '2024-03-05-mobile-device-trust',
  },
  {
    localName: 'dhmNewRules',
    sourceId: '2024-08-01-dhm-new-rules',
  },
  {
    localName: 'diEventsSearch',
    sourceId: '2022-12-19-di-events-search',
  },
  {
    localName: 'dynamicGroupsCustomAttributes',
    sourceId: '2024-06-26-dynamic-groups-custom-attributes',
  },
  {
    localName: 'globalMfaEnforcement',
    sourceId: '2024-07-02-ap-global-mfa-enforcement',
  },
  {
    localName: 'globalMfaEnforcementOptout',
    sourceId: '2024-07-02-global-mfa-enforcement-neworgs-optout',
  },
  {
    localName: 'idMCustomApiAdditionalHeaders',
    sourceId: '2024-05-27-id-m-custom-api-additional-headers',
    defaultValue: true,
  },
  {
    localName: 'identityProvidersGoogleAzure',
    sourceId: '2023-10-18-google-azure-idp',
    defaultValue: true,
  },
  {
    localName: 'idmSupportCeridianDayforceHcm',
    sourceId: '2023-04-12-dayforce-hr-user-import',
    defaultValue: true,
  },
  {
    localName: 'idpRoutingPolicies',
    sourceId: '2024-02-13-idp-routing-policies',
  },
  {
    localName: 'importAdditionalAttributesFromM365',
    sourceId: '2021-08-09-import-additional-attributes-m365',
  },
  {
    localName: 'importAgentConnectKeyView',
    sourceId: '2024-02-02-adi-import-agent-connect-key-view',
  },
  {
    localName: 'importManagerAttributeFromGoogle',
    sourceId: '2022-04-19-import-manager-attribute-google',
  },
  {
    localName: 'interactiveShell',
    sourceId: '2023-05-03-remote-assist-interactive-shell',
  },
  {
    localName: 'intrusiveGlobalMfaModalBatch1',
    sourceId: '2024-06-05-intrusive-banner-global-mfa-batch-1',
  },
  {
    localName: 'intrusiveGlobalMfaModalBatch2',
    sourceId: '2024-06-05-intrusive-banner-global-mfa-batch-2',
  },
  {
    localName: 'intrusiveGlobalMfaModalBatch3',
    sourceId: '2024-06-05-intrusive-banner-global-mfa-batch-3',
  },
  {
    localName: 'intrusiveGlobalMfaModalBatch4',
    sourceId: '2024-06-05-intrusive-banner-global-mfa-batch-4',
  },
  {
    localName: 'intrusiveGlobalMfaModalBatch5',
    sourceId: '2024-06-05-intrusive-banner-global-mfa-batch-5',
  },
  {
    localName: 'intrusiveGlobalMfaModalMtp',
    sourceId: '2024-06-05-intrusive-banner-global-mfa-mtp',
  },
  {
    localName: 'jumpCloudGoFirefox',
    sourceId: '2024-02-06-jumpcloud-go-firefox',
  },
  {
    localName: 'ironSquidOsUpdates',
    sourceId: '2022-10-27-iron-squid',
  },
  {
    localName: 'labsDeviceGroupVPN',
    sourceId: '2022-12-08-labs-device-group-vpn',
  },
  {
    localName: 'microsoftStoreIntegration',
    sourceId: '2023-12-06-microsoft-store-integration',
  },
  {
    localName: 'microsoftStoreSearch',
    sourceId: '2023-12-06-microsoft-store-application-search',
  },
  {
    localName: 'msftMdmDownloadConfigFile',
    sourceId: '2024-02-12-ppkg-admin-portal-only',
    defaultValue: false,
  },
  {
    localName: 'modernAdminAuthLinkedIdentities',
    sourceId: '2024-06-24-modern-admin-auth-linked-identities',
  },
  {
    localName: 'o365GroupExports',
    sourceId: '2022-03-31-m365-usergroup-directory-exports',
  },
  {
    localName: 'odsReports',
    sourceId: '2024-07-15-ods-reports',
  },
  {
    localName: 'osPatchMgmtPolicyReport',
    sourceId: '2022-06-27-os-patch-management-report',
  },
  {
    localName: 'passwordComplexity',
    sourceId: '2024-04-22-password-complexity',
  },
  {
    localName: 'passwordManager',
    sourceId: '2022-05-25-Password-Manager-Toggle',
  },
  {
    localName: 'passwordMinLengthChange',
    sourceId: '2023-11-07-iridium-min-length-password-change',
  },
  {
    localName: 'passwordResetViaRecoveryEmail',
    sourceId: '2021-10-14-pwd-reset-via-recovery-email',
  },
  {
    localName: 'passwordSync',
    sourceId: '2023-10-17-device-password-sync',
  },
  {
    localName: 'peripheraluiMFE',
    sourceId: '2024-05-22-peripheralui-mfe',
  },
  {
    localName: 'persistentCheckoutCart',
    sourceId: '2024-04-01-persistent-checkout-cart',
  },
  {
    localName: 'policyResultAggregation',
    sourceId: '2023-10-31-policy-result-aggregation',
  },
  {
    localName: 'privateRepo',
    sourceId: '2023-05-11-private-repo',
  },
  {
    localName: 'pwmSharedFoldersSettings',
    sourceId: '2023-02-28-password-manager-shared-folder',
  },
  {
    localName: 'remoteAssist',
    sourceId: '2022-08-01-remote-assist',
    defaultValue: true,
  },
  {
    localName: 'remoteAssistForArm64Linux',
    sourceId: '2024-01-04-remote-assist-for-arm64-linux',
  },
  {
    localName: 'remoteAssistForLinux',
    sourceId: '2023-01-27-remote-assist-for-linux',
    defaultValue: true,
  },
  {
    localName: 'saasManagement',
    sourceId: '2024-06-12-saas-management',
  },
  {
    localName: 'scimGenericClientCredentialsSupport',
    sourceId: '2024-04-24-scim-generic-client-credentials-support',
  },
  {
    localName: 'scimMtlsSupport',
    sourceId: '2024-07-09-scim-mtls-support',
  },
  {
    localName: 'scimSupportAppleBusinessManager',
    sourceId: '2024-01-24-abm-scim-oauth-connector',
  },
  {
    localName: 'silentAssist',
    sourceId: '2023-03-07-silent-assist',
  },
  {
    localName: 'splitMfaStatusColumn',
    sourceId: '2024-02-27-iridium-mfa-enrollment-status-visible',
  },
  {
    localName: 'ssoReport',
    sourceId: '2022-04-13-sso-report',
  },
  {
    localName: 'synthesizedReports',
    sourceId: '2021-04-01-synthesized-reports',
  },
  {
    localName: 'temporaryElevatedDevicePrivileges',
    sourceId: '2023-11-10-temporary-elevated-device-privileges',
  },
  {
    localName: 'updatedSubmitCase',
    sourceId: '2024-05-14-Updated-Submit-Case-AP',
  },
  {
    localName: 'usePaginationMethodForBoundDevices',
    sourceId: '2023-01-10-show-bound-devices-traversals',
  },
  {
    localName: 'usersToDevicesReport',
    sourceId: '2022-09-14-users-to-devices-report',
    defaultValue: true,
  },
  {
    localName: 'usersToDirectoriesReport',
    sourceId: '2022-09-14-users-to-directories-report',
    defaultValue: true,
  },
  {
    localName: 'usersToLdapServersReport',
    sourceId: '2022-09-14-users-to-ldap-servers-report',
    defaultValue: true,
  },
  {
    localName: 'usersToRadiusServersReport',
    sourceId: '2022-09-14-users-to-radius-servers-report',
    defaultValue: true,
  },
  {
    localName: 'usersToUserGroupsReport',
    sourceId: '2022-12-20-users-to-user-groups-report',
  },
  {
    localName: 'valueReport',
    sourceId: '2022-01-21-value-report',
  },
  {
    localName: 'vppAppUpdating',
    sourceId: '2024-04-18-vpp-app-updating',
  },
  {
    localName: 'windowsMdmAutoEnroll',
    sourceId: '2023-05-04-windows-mdm-autoenroll',
  },
  {
    localName: 'zzzFlagForTesting',
    sourceId: '2075-10-11-flag-for-testing',
    defaultValue: true,
  },
  {
    localName: 'TBTSurveyUpdates',
    sourceId: '2024-06-13-TBT-Survey',
  },
];

/**
 * Maintaining this const for legacy reasons. We are attempting to move away from it,
 * but updating all the references to this all at once was too big an effort at this time.
 *
 * @deprecated Just reference the flag names directly. No need for a intermediary const
 */
export const featureFlags = flagConfigs.reduce((prevFlags, flag) => ({
  ...prevFlags,
  // Build a mapping where the localName is both key and value.
  [flag.localName]: flag.localName,
}), {});

export const {
  initializeFlags,
  useFeatureFlags,
  FeatureFlagsModule,
  FeatureFlagMixin,
} = setupFeatureFlags(flagConfigs);

/**
 * This function is being exported for legacy tests
 * It is not expected to be used outside of tests.
 * @deprecated
 */
export function createFeatureFlagsModule() {
  return setupFeatureFlags(flagConfigs).FeatureFlagsModule;
}

export default FeatureFlagsModule;
