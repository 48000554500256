/**
 * When creating a new local storage variable, include the date in the beginning of the variable
 * e.g. `2029-07-15-reports-announcement-seen`
 * @see https://github.com/TheJumpCloud/jumpcloud-admin-portal/blob/master/docs/ui-dev-best-practices.md#include-dates-in-local-storage-variables
 */
export default {
  activityLogViewsAnnouncementDismissed: 'jcActivityLogViewsAnnouncementDismissed',
  adDistributionManagement: 'jcAdDistributionManagementDismissed',
  addDeviceNudgeReminder: 'jcAddDeviceNudgeReminder',
  addLocalUserAccountModalAcknowledged: 'jcAddSystemUsernameModalAcknowledged',
  agentLogSavedData: 'jcAgentLogSavedData',
  announcementCardState: 'announcement-card-state',
  applicationInstructionsDismissed: 'jcApplicationInstructionsDismissed',
  applicationsIDTabsTransitionAcknowledged: 'jcApplicationsIDTabsTransitionAcknowledged',
  applicationsTabsTransitionAcknowledged: 'jcApplicationsTabsTransitionAcknowledged',
  authIdpPopoverDismissed: '2022-05-30-authIdpPopoverDismissed',
  authnpoliciesInstructionsSeen: 'jcAuthnpoliciesInstructionsSeen',
  authNPoliciesZeroStateGuidedSetupCollapsed: '2022-04-21-jcAuthNPoliciesZeroStateGuidedSetupCollapsed',
  bannerDismissedPasswordManager: '2022-09-06-pwm-banner-dismissed',
  bannerDismissedUserGroupAutomatedMembers: '2022-10-26-bannerDismissedUserGroupAutomatedMembers',
  bannerDismissedUserGroupSudo: 'jcBannerDismissedUserGroupSudo',
  bindSystemToUserModalAcknowledged: 'jcBindSystemToUserModalAcknowledged',
  casePortalIntroModalSeen: '2022-11-10-casePortalIntroModalSeen',
  checklistConsiderationDismissed: '2023-11-10-jcChecklistConsiderationDismissed',
  commandtemplateAnnouncementSeen: '2022-11-03-commandtemplateAnnouncementSeen',
  commandQueueItemsRowsPerPage: '2023-02-24-command-queue-items-per-page',
  columnsDevicePageAnnouncementAcknowledged: 'jcColumnsDevicePageAnnouncementAcknowledged',
  createEnrollmentTokenNudge: 'jcCreateEnrollmentTokenNudge',
  customAppRepoDevicesRowsPerPage: '2023-06-27-customAppRepoDevicesRowsPerPage',
  customAppRepoStatusRowsPerPage: '2023-06-27-customAppRepoStatusRowsPerPage',
  customAttributesNudgeDismissed: '2024-08-22-customAttributesNudgeDismissed',
  customEmailAnnouncementSeen: 'jcCustomEmailAnnouncementSeen',
  deviceInstructionsDismissed: 'jcDeviceInstructionsDismissed',
  devicesMainPanelLandingTab: 'jcDevicesMainPanelLandingTab',
  dynamicUserGroupsShiftDismissed: '2023-08-11-dynamic-user-groups-shift',
  exportAnnouncementDeviceDismissed: 'jcExportAnnouncementDeviceDismissed',
  exportAnnouncementUserDismissed: 'jcExportAnnouncementUserDismissed',
  gettingStartedPopoverSeen: 'jcGettingStartedPopoverSeen',
  globalFallbackPolicyExpanded: 'jcGlobalFallbackPolicySettingExpanded',
  googleEmmEnrollmentSettings: 'jcGoogleEmmEnrollmentSettings',
  googleEnterpriseSignUpUrlName: 'jcGoogleEnterpriseSignUpUrlName',
  googleOrigin: 'jcLogin',
  groupsInstructionsDismissed: 'jcGroupsCollapsableDismissed',
  gsuiteInstructionsDismissed: 'jcGsuiteInstructionsDismissed',
  gsuiteDistributionManagement: 'jcGsuiteDistributionManagementDismissed',
  hideMfaNextSteps: 'jcHideMfaNextSteps',
  idmCustomRestOauthAppCatalogueDismissed: '2022-09-14-jcIdmCustomRestOauthAppCatalogueDismissed',
  idpProTipDismissed: '2023-06-23-jcIdp-protip-dismissed',
  importAdditionalGoogleAttributeDismissed: 'jcAdditionalGoogleAttributeDismissed',
  insightsInstructionsDismissed: 'jcInsightsInstructionsDismissed',
  instructionsCommandQueueDetailDismissed: 'jcInstructionsCommandQueueDetailDismissed',
  jumpcloudGoNudgeReminder: 'jcGoNudgeReminder',
  lastActiveTimeKey: 'lastActiveTime',
  mdmBigSurReadiness: 'jcMdmBigSurReadiness',
  membershipFiltersEnabled: 'membershipFiltersEnabled',
  orgLogoUrl: 'jcLogoUrl',
  osManagementPopoverDismissed: 'jcOsManagementPopoverDismissed',
  o365InstructionsDismissed: 'jcO365InstructionsDismissed',
  passwordManagerAppVersionsRowsPerPage: '2022-09-22-passwordmanager-appversions-per-page',
  passwordManagerBindingGroupsRowsPerPage: '2024-03-07-passwordmanager-binding-groups-per-page',
  passwordManagerItemsRowsPerPage: '2022-11-01-passwordmanager-items-per-page',
  passwordManagerUsersRowsPerPage: '2022-08-11-passwordmanagerusers-per-page',
  paymentPromptDismissed: 'jcPaymentPromptDismissed',
  pwmRestoreRequestsRowsPerPage: '2023-02-20-passwordManagerRestoreRequestsRowsPerPage',
  pwmUsersListRefreshAnnouncement: '2022-09-16-pwm-users-refresh-announcement',
  radiusInstructionsDismissed: 'jcRadiusInstructionsDismissed',
  recommendedApplicationsCarouselDismissed: 'jcRecommendedApplicationsCarouselDismissed',
  recommendedCommandQueueDismissed: 'jcRecommendedCommandQueueDismissed',
  recommendedCommandsCarouselDismissed: 'jcRecommendedCommandsCarouselDismissed',
  recommendedPoliciesCarouselDismissed: 'jcRecommendedPoliciesCarouselDismissed',
  recommendedPoliciesDismissed: 'jcRecommendedPoliciesDismissed',
  remoteAssistWelcomeModalSeen: '2023-03-30-jcRemoteAssistWelcomeModalSeen',
  remoteWorkTipsPanelAnnouncementSeen: 'jcRemoteWorkTipsPanelAnnouncementSeen',
  reportsInstructionsDismissed: 'jcReportsInstructionsDismissed',
  resourcesPanelAnnouncementSeen: 'jcResourcesPanelAnnouncementSeen',
  selfServiceAccountProvisioningNudge: 'jcSelfServiceAccountProvisioningNudge',
  restrictedFieldsDismissed: '2023-10-13-jcRestrictedFieldsDismissed',
  ruleItemsRowsPerPage: '2024-06-13-rule-items-per-page',
  sharedFolderGroupsRowsPerPage: '2023-01-31-sharedfoldergroups-per-page',
  sharedFoldersRowsPerPage: '2022-08-11-sharedfolders-per-page',
  sharedFolderSystemUsersRowsPerPage: '2022-08-11-sharedfoldersytemusers-per-page',
  supportCasesRowsPerPage: '2022-10-05-support-cases-rows-per-page',
  systemInstructionsDismissed: 'jcSystemInstructionsDismissed',
  systemMainPanelColumns: 'jcSystemMainPanelColumns',
  systemMainPanelColumnsCacheFlag: 'jcSystemMainPanelColumnsCacheFlag',
  systemUserMainPanelColumns: 'jcSystemUserMainPanelColumns',
  timeBasedTrialExtendNudgeSeen: '2023-11-21-trial-extend-nudge-seen',
  timeBasedTrialMFANudgeSeen: '2023-11-09-trial-mfa-nudge-seen',
  timeBasedTrialMidwayNudgeSeen: '2023-11-09-trial-midway-nudge-seen',
  timeBasedTrialPurchasePlanNudgeSeen: '2023-11-30-trial-purchase-plan-nudge-seen',
  universityModalSeen: 'jcUniversityModalSeen',
  userGroupDistributionEmail: 'jcUserGroupDistributionEmailDismissed',
  userScheduleStartDateTipDismissed: '2022-08-31-jcuserScheduleStartDateTipDismissed',
  userStateManualBanner: '2022-08-15-jcUserStateManualBannerDismissed',
  uuid: 'jcUUID',
  vppAnnouncementPopoverDismissed: 'jcVppAnnouncementPopoverDismissed',
  welcomeHomeModalSeen: 'jcWelcomeHomeModalSeen',
  welcomeModalSeen: 'jcWelcomeModalSeen',
  windowsAppModalSeen: 'jcWindowsAppModalSeen',
  deviceListTableRowsPerPage: '2024-06-25-deviceListTableRowsPerPage',
};
